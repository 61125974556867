/** @jsx jsx */
import { jsx, Card, Box, Grid } from 'theme-ui'
import { useState, useEffect } from 'react'
import Layout from '../../components/layout'
import Wrapper from '../../components/layout/wrapper'
import Inner from '../../components/layout/inner'
import { responsive } from '../../utils/helpers'
import Table from '../../components/leagueTable'
import NextMatch from '../../components/nextMatch'
import Accordian from '../../components/accordion'
import { Archive } from '../../components/hero'
import moment from 'moment'
import { getCalendars } from '../../api_aws/opta'
import MetaData from '../../components/metadata'

const LeagueTable = props => {
  const [filter, setFilter] = useState()
  const [yearFrom, setYearFrom] = useState()
  const [type, setType] = useState()
  const [calendars, setCalendars] = useState()
  const [calendar, setCalendar] = useState()

  const yearOptions = () => {
    const years = []
    const dateStart = moment()
    const dateEnd = moment().subtract(1, 'years')
    while (dateEnd.diff(dateStart, 'months') <= 0) {
      const to = dateStart.clone().add(1, 'year')
      years.push({
        label: `${dateStart.format('YYYY')}/${to.format('YYYY')}`,
        value: dateStart.format('YYYY'),
      })
      dateStart.subtract(1, 'year')
    }
    return years
  }

  const typeOptions = [
    {
      label: 'Full Table',
      value: '',
    },
    {
      label: 'Home Form',
      value: 'home',
    },
    {
      label: 'Away Form',
      value: 'away',
    },
  ]

  const filterOptions = [
    {
      label: 'Position',
      value: 'position',
    },
    {
      label: 'Points',
      value: 'points',
    },
    {
      label: 'Goals For',
      value: 'goalsFor',
    },
    {
      label: 'Goals Against',
      value: 'goalsAgainst',
    },
    {
      label: 'Goals Difference',
      value: 'goaldifference',
    },
    {
      label: 'Won',
      value: 'matchesWon',
    },
    {
      label: 'Drawn',
      value: 'matchesDrawn',
    },
    {
      label: 'Lost',
      value: 'matchesLost',
    },
  ]

  useEffect(() => {
    ;(async () => {
      const calendars = await getCalendars()
      if (calendars.success) {
        setCalendars(calendars.data)
        setCalendar(calendars.data[0].id)
      }
    })()
  }, [])

  const handleYearChange = e => {
    setCalendar(e.value)
  }

  return (
    <Layout {...props}>
      <MetaData title="League Table" />
      <Archive
        title="League Table"
        variant="center"
        filters={[
          {
            options: filterOptions,
            onChange: e => setFilter(e.value),
          },
          {
            options: typeOptions,
            onChange: e => setType(e.value),
          },
          {
            options: calendars?.map(x => ({
              value: x.id,
              label: x.name,
            })),
            onChange: handleYearChange,
          },
        ]}
      />
      <Wrapper>
        <Inner>
          <Grid columns={responsive(1, '65% 1fr')} gap={10} pt={11} mb={10}>
            <Box>
              <Card variant="slim">
                <Table calendar={calendar} type={type} filter={filter} />
              </Card>
            </Box>
            <Box mb={5}>
              <Card variant="slim">
                <Accordian heading="Next Match" open noBorder>
                  <Box p={2}>
                    <NextMatch
                      sx={{ mb: 4 }}
                      tickets
                      // travel
                      live
                      location
                      variant="stacked"
                      color="black"
                    />
                  </Box>
                </Accordian>
              </Card>
            </Box>
          </Grid>
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export default LeagueTable
