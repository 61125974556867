/** @jsx jsx */
import { useState } from 'react'
import { jsx, Text, Box } from 'theme-ui';
import Arrow from '../../icons/arrow-back.svg'

const Accordion = (props) => {

  const [open, setOpen] = useState(props.open ? true : false)

  const answer = {
    borderBottom: props.noBorder ? 'none' : '1px solid',
    borderColor: 'greyborder',
    display: open ? 'block' : 'none',
    // p: 4
  }

  const arrow = {
    position: 'absolute',
    right: 3,
    top: 'calc(50% - 5px)',
    transform: open ? 'rotate(90deg)' : 'rotate(-90deg)',
    width: '19px !important',
    height: '13px'
  }

  const question = {
    paddingRight: '50px',
    paddingLeft: props.variant === 'light' ? 4 : '50px',
    paddingTop: props.variant === 'light' ? 5 : false,
    paddingBottom: props.variant === 'light' ? 5 : false,
    position: 'relative',
    // pt: 4,
    // pb: 4,
    borderBottom: props.variant === 'light' ? '1px solid' : '',
    borderColor: 'greyborder',
    fontSize: props.variant === 'light' ? 4 : 5,
    fontWeight: props.variant === 'light' ? 400 : 700,
    lineHeight: 'body'
  }

  const onClick = props.collapsable ? () => setOpen(!open) : null

  return (
    <Box className={props.className}>
      <Text onClick={onClick} variant={props.variant === 'light' ? false : 'banner'} sx={question}>{props.heading}
        {props.collapsable &&
          <Arrow sx={arrow} />
        }
      </Text>
      <Box sx={answer}>
        {props.children}
      </Box>
    </Box>
  )
}

export default Accordion