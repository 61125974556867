/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { selectStyles } from '../../styles/select'
import Select from 'react-select'

const Filters = () => {

  return (
    <Grid columns={3}>
      <Select 
        styles={selectStyles}
        isSearchable={false}
        defaultValue={{ value: 'Full Table', label: 'Full Table' }}
        options={[
          { value: 'Full Table', label: 'Full Table' },
          { value: 'Home Form', label: 'Home Form' },
          { value: 'Away Form', label: 'Away Form' },
          { value: 'Last Ten', label: 'Last Ten' }
        ]} 
      />
      <Select 
        styles={selectStyles}
        isSearchable={false}
        defaultValue={{ value: 'Position', label: 'Position' }}
        options={[
          { value: 'Position', label: 'Position' },
          { value: 'Points', label: 'Points' },
          { value: 'Goals for', label: 'Goals for' },
          { value: 'Goals against', label: 'Goals against' },
          { value: 'Goals difference', label: 'Goals difference' },
          { value: 'Won', label: 'Won' },
          { value: 'Drawn', label: 'Drawn' },
          { value: 'Lost', label: 'Lost' },
        ]} 
      />
      <Select 
        styles={selectStyles}
        isSearchable={false}
        defaultValue={{ value: '2020/21', label: '2020/21' }}
        options={[
          { value: '2020/21', label: '2020/21' },
          { value: '2019/20', label: '2019/20' },
          { value: '2018/19', label: '2018/19' }
        ]}
      />
    </Grid>
  )
}

export default Filters