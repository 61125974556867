/** @jsx jsx */
import { jsx, Text, Box, useThemeUI, get } from 'theme-ui'
import React, { useEffect, useState, useCallback } from 'react'
import Filters from './filters'
import Triangle from '../shapes/triangle'
import { Inline } from 'raam'
import { getLeagueTable } from '../../api_aws/opta'
import Logo from '../versus/logo'
import { useMediaQuery } from 'react-responsive'
import { responsive } from '../../utils/helpers'

const FGRNAME = 'Forest Green Rovers'

const LeagueTable = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 940px)' })
  const { theme } = useThemeUI()
  const [loading, setLoading] = useState(false)
  const [league, setLeague] = useState([])
  const [indexes, setIndexes] = useState([])

  const filter = useCallback(
    results => {
      if (!props.filter) return results
      return [...results].sort((a, b) => {
        switch (props.filter) {
          case 'position':
            return a.rank - b.rank
          default:
            return b[props.filter] - a[props.filter]
        }
      })
    },
    [props.filter]
  )

  useEffect(() => {
    ;(async () => {
      setLeague([])
      setLoading(true)
      const opta = await getLeagueTable(
        props.calendar,
        props.type,
        props.league
      )
      setLoading(false)
      if (!opta.success) return

      const data = filter(opta.data)
      setLeague(data)

      // Find FGR
      const fgr = data.filter(x => {
        return x.contestantClubName === FGRNAME
      })
      if (!fgr.length) return
      // Find FGR's position, as well as all teams below and above
      const fgrPosition = fgr[0].rank
      let before = [] // list of indexes
      for (let i = 0; i < fgrPosition - 1; i++) {
        before.push(i)
      }
      let after = [] // list of indexes
      for (let i = opta.data.length; i > fgrPosition - 1; i--) {
        after.push(i)
      }

      const toShow = [fgrPosition - 1] // list of indexes
      // Add up to 2 teams from above
      before
        .reverse()
        .filter((_, i) => {
          return i <= 1
        })
        .map(x => toShow.push(x))
      // Find how many we're missing to reach 5 in total
      const defecit = 5 - toShow.length
      // Fill defecit with teams from below
      if (defecit) {
        after
          .reverse()
          .filter((_, i) => {
            return i <= defecit - 1
          })
          .map(x => toShow.push(x))
      }

      setIndexes(toShow)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setLeague,
    props.calendar,
    props.type,
    props.league,
    // filter,
  ])

  useEffect(() => {
    setLeague(l => filter(l))
  }, [filter])

  const tableContainerSX = {
    width: '100%',
    overflowX: 'auto',
    position: 'relative',
  }

  const tableSX = {
    width: '100%',
    paddingLeft: 2,
    paddingRight: 2,
    // minWidth: props.variant === 'small' ? '0px' : '570px',
  }

  const theadSX = {
    backgroundColor: 'background',
  }

  const rowSX = {
    borderBottom: '1px solid',
    borderColor: 'background',

    td: {
      paddingTop: 2,
      paddingBottom: 2,
      verticalAlign: 'middle',
    },
  }

  const rankChangeSX = {
    width: '4%',
    textAlign: 'center',
  }

  const rankSX = {
    width: '3%',
  }

  const crestSX = {
    width: '7%',
    textAlign: 'center',
  }

  const nameSX = {
    width: responsive('6%', '18%'),
  }

  const cellSX = {
    width: '6%',
  }

  const cellMobileSX = {
    width: responsive('4%', '6%', 3),
  }

  const lastGamesSX = {
    width: '20%',
  }

  const imageSX = {
    width: isMobile ? '24px' : '32px',
    height: isMobile ? '24px' : '32px',
    margin: '0 auto',
  }

  const result = {
    fontFamily: 'heading',
    fontSize: 4,
    width: '18px',
    height: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const lose = {
    ...result,
    bg: 'black',
    color: 'white',
  }

  const win = {
    ...result,
    bg: 'primary',
  }

  const draw = {
    ...result,
    bg: 'background',
  }

  const Lose = () => {
    return <Text sx={lose}>L</Text>
  }

  const Win = () => {
    return <Text sx={win}>W</Text>
  }

  const Draw = () => {
    return <Text sx={draw}>D</Text>
  }

  const RankChange = ({ current, previous }) => {
    if (!current || !previous) return <td sx={rankChangeSX}></td>
    const arrow =
      current === previous ? (
        <RankSame />
      ) : current < previous ? (
        <RankUp />
      ) : (
        <RankDown />
      )
    return <td sx={rankChangeSX}>{arrow}</td>
  }

  const RankSame = () => {
    return <Triangle width="8" height="8" colour={get(theme, 'colors.arrow')} />
  }

  const RankDown = () => {
    return (
      <Triangle
        width="8"
        height="8"
        sx={{ transform: 'rotate(90deg)' }}
        colour={get(theme, 'colors.red')}
      />
    )
  }

  const RankUp = () => {
    return (
      <Triangle
        width="8"
        height="8"
        sx={{ transform: 'rotate(-90deg)' }}
        colour={get(theme, 'colors.primary')}
      />
    )
  }

  const Rank = ({ children }) => {
    return (
      <td sx={rankSX}>
        <Text>{children}</Text>
      </td>
    )
  }

  const Crest = ({ name, image }) => {
    return (
      <td sx={crestSX}>
        {!image && <Logo name={name} sx={imageSX} variant="small" />}
        {image && <img sx={imageSX} src={image} alt={name} />}
      </td>
    )
  }

  const Cell = ({ children, className }) => {
    return (
      <td className={className}>
        <Text>{children}</Text>
      </td>
    )
  }

  const LastGames = ({ data }) => {
    const split = data.split('')
    split.pop()
    return (
      <td sx={lastGamesSX}>
        <Inline gap={'10px'} sx={{ overflow: 'visible' }}>
          {split.map((x, i) => {
            return <LastGame data={x} key={i} />
          })}
        </Inline>
      </td>
    )
  }

  const LastGame = ({ data }) => {
    switch (data) {
      case 'L':
        return <Lose />
      case 'W':
        return <Win />
      case 'D':
        return <Draw />
      default:
        return null
    }
  }

  const divisionLine = index => {
    switch (index) {
      case 0:
        return 'primary'
      case 6:
        return 'black'
      case 19:
        return 'red'
      default:
        return 'background'
    }
  }

  const Team = x => {
    const isFgr = x.contestantClubName === FGRNAME
    return (
      <tr
        sx={{
          ...rowSX,
          borderColor: divisionLine(x.index),
          backgroundColor: isFgr ? 'primarylight' : 'transparent',
        }}
      >
        <RankChange current={x.rank} previous={x.lastRank} />
        <Rank>{x.rank}</Rank>
        <Crest image={x.crest} name={x.contestantShortName} />
        <Cell sx={nameSX}>{x.contestantShortName}</Cell>
        <Cell sx={cellMobileSX}>{x.matchesPlayed}</Cell>
        {props.variant !== 'small' && (
          <React.Fragment>
            {!isMobile && <Cell sx={cellMobileSX}>{x.matchesWon}</Cell>}
            {!isMobile && <Cell sx={cellSX}>{x.matchesDrawn}</Cell>}
            {!isMobile && <Cell sx={cellMobileSX}>{x.matchesLost}</Cell>}
            {!isMobile && <Cell sx={cellSX}>{x.goalsFor}</Cell>}
            {!isMobile && <Cell sx={cellSX}>{x.goalsAgainst}</Cell>}
          </React.Fragment>
        )}
        <Cell sx={cellSX}>{x.goaldifference}</Cell>
        <Cell sx={cellSX}>{x.points}</Cell>
        {props.variant !== 'small' && x.lastSix && isDesktop && (
          <LastGames data={x.lastSix} />
        )}
      </tr>
    )
  }

  const Headings = () => {
    return (
      <tr sx={rowSX}>
        <td sx={rankChangeSX}></td>
        <td sx={rankSX}>
          <Text variant="tableHeading">Pos</Text>
        </td>
        <td sx={crestSX}></td>
        <td sx={nameSX}>
          <Text variant="tableHeading">Team</Text>
        </td>
        <td sx={cellMobileSX}>
          <Text variant="tableHeading">P</Text>
        </td>
        {props.variant !== 'small' && (
          <React.Fragment>
            {!isMobile && (
              <td sx={cellMobileSX}>
                <Text variant="tableHeading">W</Text>
              </td>
            )}
            {!isMobile && (
              <td sx={cellSX}>
                <Text variant="tableHeading">D</Text>
              </td>
            )}
            {!isMobile && (
              <td sx={cellMobileSX}>
                <Text variant="tableHeading">L</Text>
              </td>
            )}
            {!isMobile && (
              <td sx={cellSX}>
                <Text variant="tableHeading">F</Text>
              </td>
            )}
            {!isMobile && (
              <td sx={cellSX}>
                <Text variant="tableHeading">A</Text>
              </td>
            )}
          </React.Fragment>
        )}
        <td sx={cellSX}>
          <Text variant="tableHeading">GD</Text>
        </td>
        <td sx={cellSX}>
          <Text variant="tableHeading">PTS</Text>
        </td>
        {props.variant !== 'small' && !props.type && isDesktop && (
          <td sx={lastGamesSX}>
            <Text variant="tableHeading">Last 5 Games</Text>
          </td>
        )}
      </tr>
    )
  }

  const NoResults = () => {
    if (loading || league.length) return null
    return (
      <tr>
        <td sx={{ p: 6 }}>
          <p>No Results</p>
        </td>
      </tr>
    )
  }

  return (
    <React.Fragment>
      {props.filters && <Filters />}
      <Box sx={tableContainerSX}>
        <table sx={tableSX}>
          <thead sx={theadSX}>
            <Headings />
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td sx={{ p: 6 }}>
                  <p>Loading...</p>
                </td>
              </tr>
            )}
            <NoResults />
            {league?.map((x, i) => {
              if (props.variant === 'small') {
                if (indexes.includes(i))
                  return <Team {...x} index={i} key={i} />
              } else {
                return <Team {...x} index={i} key={i} />
              }
              return null
            })}
          </tbody>
        </table>
      </Box>
    </React.Fragment>
  )
}

export default LeagueTable
